define('ember-inputmask/components/zip-code-input', ['exports', 'ember', 'ember-inputmask/components/input-mask'], function (exports, _ember, _emberInputmaskComponentsInputMask) {
  'use strict';

  /**
   * `{{zip-code-input}}` component.
   *
   * Displays an input that masks a US ZIP code.
   *
   * Future: Add config options that allow users to set locality
   * app wide.
   *
   * OPTIONS:
   *   fullCode - bool
   *     Allows users to optionally enter the full ZIP+4 area code.
   */

  exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
    mask: '99999',

    fullCode: false,

    updateMask: function updateMask() {
      if (this.get('fullCode')) {
        this.set('mask', '99999[-9999]');
      } else {
        this.set('mask', '99999');
      }

      this._super();
    },

    _maskShouldChange: _ember['default'].observer('mask', 'fullCode', function () {
      _ember['default'].run.once(this, 'updateMask');
    })
  });
});