define('ember-cli-font-awesome/components/fa-icon', ['exports', 'ember', 'ember-cli-font-awesome/utils/try-match'], function (exports, _ember, _emberCliFontAwesomeUtilsTryMatch) {
  'use strict';

  var computed = _ember['default'].computed;

  var FaIconComponent = _ember['default'].Component.extend({
    tagName: 'i',

    classNames: ['fa'],

    classNameBindings: ['iconCssClass', 'flipCssClass', 'rotateCssClass', 'sizeCssClass', 'pullCssClass', 'stackCssClass', 'spin:fa-spin', 'fixedWidth:fa-fw', 'listItem:fa-li', 'border:fa-border', 'pulse:fa-pulse', 'inverse:fa-inverse'],

    attributeBindings: ['ariaHiddenAttribute:aria-hidden', 'title'],

    iconCssClass: computed('icon', 'params.[]', function () {
      var params = this.get('params');
      var icon = this.get('icon') || params && params.length && params[0];
      if (icon) {
        return (0, _emberCliFontAwesomeUtilsTryMatch['default'])(icon, /^fa-/) ? icon : 'fa-' + icon;
      }
    }),

    flipCssClass: computed('flip', function () {
      var flip = this.get('flip');
      if (flip) {
        return (0, _emberCliFontAwesomeUtilsTryMatch['default'])(flip, /^fa-flip/) ? flip : 'fa-flip-' + flip;
      }
    }),

    rotateCssClass: computed('rotate', function () {
      var rotate = this.get('rotate');
      if (rotate) {
        if ((0, _emberCliFontAwesomeUtilsTryMatch['default'])(rotate, /^fa-rotate/)) {
          return rotate;
        } else {
          return 'fa-rotate-' + rotate;
        }
      }
    }),

    sizeCssClass: computed('size', function () {
      var size = this.get('size');
      if (size) {
        if ((0, _emberCliFontAwesomeUtilsTryMatch['default'])(size, /^fa-/)) {
          return size;
        } else if ((0, _emberCliFontAwesomeUtilsTryMatch['default'])(size, /(?:lg|x)$/)) {
          return 'fa-' + size;
        } else {
          return 'fa-' + size + 'x';
        }
      }
    }),

    pullCssClass: computed('pull', function () {
      var pull = this.get('pull');
      if (pull) {
        return 'fa-pull-' + pull;
      }
    }),

    stackCssClass: computed('stack', function () {
      var stack = this.get('stack');
      if (stack) {
        if ((0, _emberCliFontAwesomeUtilsTryMatch['default'])(stack, /^fa-/)) {
          return stack;
        } else if ((0, _emberCliFontAwesomeUtilsTryMatch['default'])(stack, /x$/)) {
          return 'fa-stack-' + stack;
        } else {
          return 'fa-stack-' + stack + 'x';
        }
      }
    }),

    ariaHiddenAttribute: computed('ariaHidden', function () {
      var ariaHidden = this.get('ariaHidden');
      return ariaHidden !== false ? true : undefined;
    })
  });

  FaIconComponent.reopenClass({
    positionalParams: 'params'
  });

  exports['default'] = FaIconComponent;
});